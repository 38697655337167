<template>
  <v-container>
    <DialogConfirmation
      :scrollable="true"
      :width="950"
      :show="exibirDialogAdicionarFilial"
      :key="keyDialog"
      :tag="tagDialog"
      :title="dialogTitle"
      @close="fecharModalAdicionarFilial()"
      @confirm="cadastrarFilial()"
    >
      <template v-if="tagDialog === 'add'" v-slot:body>
        <v-form class="form-fields fields">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados da Filial" />
          <FormField ref="formCadastrarParceiro" :validate="keyFormValidacao" :fields="fields" />
        </v-form>
      </template>
    </DialogConfirmation>


    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="filiais"
      :pagination="pagination"
      :loading="carregandoAcaoGenerica"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
      @clickRow="detalharFilial($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <ButtonMain
            class="mr-2"
            :primary="true"
            text="Adicionar Filial"
            @click.native="abrirModalAdicionarFilial('add')"
          />

          <v-spacer></v-spacer>

          <ButtonMain
            @click.native="abrirDialogFiltroParceiro()"
            text="filtrar"
            :primary="true"
          ></ButtonMain>

          <ButtonMain
            v-if="filtroAplicado"
            text="Limpar filtros"
            class="ml-2 warning"
            :primary="true"
            @click.native="
              limparFiltro()
              filtroAplicado = false
            "
          ></ButtonMain>

          <DialogConfirmation
            title="Filtros"
            :scrollable="true"
            :width="720"
            :show="dialogFiltroAberto"
            :key="keyDialog"
            :tag="'add'"
            @close="dialogFiltroAberto = false"
            @confirm="aplicarFiltroFilial(); dialogFiltroAberto = false"
          >
            <template v-slot:body>
              <v-row class="mt-3">
                <InputField
                  label="Razão Social"
                  :cols="6"
                  :valueField="filtro.nome"
                  @update:value="filtro.nome = $event"
                />

                <InputMaskField
                  label="Filtrar por CNPJ"
                  maskFormat="##.###.###/####-##"
                  :valueField="filtro.cnpj"
                  :cols="6"
                  @update:value="filtro.cnpj = $event"
                />

                <SelectField
                  label="Filtrar por Status"
                  keyValue="id"
                  keyName="descricao"
                  :items="listaStatusFiliais"
                  :cols="6"
                  :valueField="filtro.status"
                  @update:value="filtro.status = $event"
                />
              </v-row>
            </template>
          </DialogConfirmation>

        </v-toolbar>
      </template>

      <template v-slot:[`item.cnpj`]="{ item }">
        <span>{{ item.cnpj | VMask('##.###.###/####-##') }}</span>
      </template>

      <template v-slot:[`item.idStatus`]="{ item }">
        <StatusChip :color="getColor(item.idStatus)">
          {{ item.descricaoStatus ? item.descricaoStatus : 'NÃO INFORMADO' }}
        </StatusChip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="green"
              class="mr-2"
              :loading="item.idStatus !== 2 && item.atualiazandoStatus"
              :disabled="item.idStatus === 2 || item.atualiazandoStatus"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Tornar Ativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação alterará o status da filial ${item.nome} para
                    ATIVO. Deseja continuar? `
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    atualizarStatusFilial(item, 2)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="red"
              class="white--text"
              :loading="item.idStatus === 2 && item.atualiazandoStatus"
              :disabled="item.idStatus !== 2 || item.atualiazandoStatus"
            >
              <v-tooltip top>
                <template small v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>Tornar Inativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação alterará o status da filial ${item.nome} para
                    INATIVO. Deseja continuar?`
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    atualizarStatusFilial(item, 3)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import utilService from '@/services/UtilService'
import institucionalService from '@/services/InstitucionalService'
import cepService from '@/services/CepService'
import apiService from '@/services/api'
import TitleH2 from '@/components/atoms/title/TitleH2'
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from '@/components/atoms/StatusChip.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'

export default {
  components: {
    TitleH2,
    CrudTable,
    ButtonMain,
    FormField,
    DialogConfirmation,
    StatusChip,
    InputMaskField,
    SelectField,
    InputField
  },

  data() {
    return {
      keyPaginacao: 0,
      keyFormValidacao: 0,
      cepValido: true,
      dialogTitle: 'Adicionar Filial',
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      exibirDialogAdicionarFilial: false,
      filtroAplicado: false,
      dialogFiltroAberto: false,
      carregandoAcaoGenerica: false,
      tagDialog: '',
      itemsPerfis: [],
      listaStatusFiliais: [],
      listaUf: [],
      enderecoCep: [],

      filtro: {
        nome: '',
        cnpj: '',
        status: '',
      },
      keyDialog: '',
      filiais: [],
      headers: [
        { text: 'Razão social', value: 'nome', width: 350, sortable: false },
        { text: 'CNPJ', value: 'cnpj', align: 'center', sortable: false },
        { text: 'Telefone', value: 'telefone', align: 'center', sortable: false },
        { text: 'Cidade', value: 'cidade', align: 'center', sortable: false },
        { text: 'Status', align: 'center', value: 'idStatus', sortable: false },
        { text: '', value: 'actions', width: 150, sortable: false }
      ],
      fields: {
        nome: {
          label: 'Razão social',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        cnpj: {
          label: 'CNPJ',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##.###.###/####-##',
        },
        inscricaoMunicipal: {
          label: 'Inscrição Municipal',
          valueField: '',
          cols: 4,
          value: '',
          field: 'InputField'
        },
        inscricaoEstadual: {
          label: 'Inscrição Estadual',
          valueField: '',
          cols: 4,
          value: '',
          field: 'InputField'
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          cols: 2,
          value: '',

          field: 'InputMaskField',
          maskFormat: '##',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [(value) => !!value || 'Campo obrigatório']
        },
        whatsApp: {
          label: 'Whatsapp',
          valueField: '',
          value: '',
          cols: 4,
          maskFormat: '(##) #####-####',
          field: 'InputMaskField'
        },
        instagran: {
          label: 'Instagram',
          valueField: '',
          value: '',
          cols: 4,
          field: 'InputField'
        },

        enderecoResidencial: {
          cols: 12,
          label: 'Endereço Comercial',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          rules: [
            (value) => !!value || 'Campo obrigatório',
            (value) => (this.cepValido && !!value) || 'CEP invalido'
          ],
          field: 'InputMaskField',
          maskFormat: '#####-###'
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          rules: [(value) => !!value || 'Campo obrigatório'],
          field: 'InputField'
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: '',
          cols: 6,

          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        idUf: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        idCidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || 'Campo obrigatório'],
        },
        pontoReferencia: {
          label: 'Ponto de Referencia',
          valueField: null,
          cols: 7,
          field: 'InputField',
          value: ''
        },
      },
      itensPorPagina: 10,
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  created() {
    this.receberUFs();
    this.receberStatusFiliais();

    const paginacao = { page: this.pagination.page, size: this.itensPorPagina };
    this.receberFiliais(paginacao);
  },

  methods: {
    receberFiliais(paginacao) {
      this.carregandoAcaoGenerica = true;
      const filtro = { filial: 'true', ...paginacao };
      institucionalService.filiais(filtro)
        .then(({ data }) => {
          this.filiais = data.content.map(filial => {
            return {
              ...filial,
              telefone: this.setTelefone(filial.ddd, filial.telefone),
              cidade: filial.nomeCidade,
              atualiazandoStatus: false,
            }
          });
          this.pagination.total = data.totalPages
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => this.carregandoAcaoGenerica = false);
    },

    receberStatusFiliais() {
      utilService.listaStatusAtivacao()
        .then(({ data }) => {
          this.listaStatusFiliais = data
        })
    },

    verificarSeHaCampoObrigatorioNulo(campos) {
      let campoInvalido = false;
      for(const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value;
        const regras = value.rules;
        let erroCampo = false;

        if(regras && regras.length) {
          for(const regra of regras) {
            if(regra(valorCampo) ===  'Campo obrigatório') {
              erroCampo = true;
              break;
            }
          }
        }

        if(erroCampo) {
          campoInvalido = true;
          break;
        };
      }

      return campoInvalido;
    },

    cadastrarFilial() {
      if (this.verificarSeHaCampoObrigatorioNulo(this.fields)) {
        ++this.keyFormValidacao;
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      const body = {
        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        cnpj: this.fields.cnpj.value,
        complemento: this.fields.complemento.value,
        ddd: this.fields.ddd.value,
        email: this.fields.email.value,
        cidadeEndereco: this.fields.idCidadeEndereco.value,
        logradouro: this.fields.logradouro.value,
        nome: this.fields.nome.value,
        numero: this.fields.numero.value,
        pontoReferencia: this.fields.pontoReferencia.value,
        telefone: this.fields.telefone.value,
        inscricaoMunicipal: this.fields.inscricaoMunicipal.value,
        inscricaoEstadual: this.fields.inscricaoEstadual.value,
        whatsApp: this.fields.whatsApp.value,
        instagran: this.fields.instagran.value,
      }

      this.carregandoAcaoGenerica = true;
      institucionalService
        .cadastrarFilial(body)
        .then(() => {
          this.fecharModalAdicionarFilial();
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => this.carregandoAcaoGenerica = false)
    },

    verificarCep(cep) {
      cepService.buscarCep(cep)
        .then(({ data }) => {
          if (data.erro) {
            this.cepValido = false;
            this.fields.bairro.valueField = null;
            this.fields.logradouro.valueField = null;
            this.fields.idUf.valueField = null;
            this.fields.idUf.value = null;
            this.fields.idCidadeEndereco.valueField = null;
            this.fields.idCidadeEndereco.value = null;
            return;
          }

          this.cepValido = true;
          this.fields.bairro.valueField = data.bairro;
          this.fields.logradouro.valueField = data.logradouro;
          this.enderecoCep = data;
        });
    },

    receberCidades(id) {
      apiService.getCidadeFromUF(id).then(({ data }) => {
        this.fields.idCidadeEndereco.items = data
      })
    },

    receberUFs() {
      apiService.getUf().then(({ data }) => {
        this.listaUf = data
        this.fields.idUf.items = data
      })
    },

    resetarPaginacao() {
      ++this.keyPaginacao;
    },

    setTelefone(ddd, telefone) {
      if(ddd && telefone) return `(${ddd}) ${telefone}`;
      if(!ddd && telefone) return `${telefone}`;
      return null;
    },

    getColor(status) {
      if (status === 1) return 'orange';
      if (status === 2) return 'green';
      if (status === 3) return 'red';
      return '';
    },

    abrirDialogFiltroParceiro() {
      this.filtro = { nome: '', cnpj: '', status: '' }
      this.dialogFiltroAberto = true;
    },

    detalharFilial(item) {
      this.$router.push({ name: 'DetalhesFilial', params: { id: item.id } })
    },

    fecharModalAdicionarFilial() {
      this.keyDialog++
      this.exibirDialogAdicionarFilial = false;
      const paginacao = { page: this.pagination.page, size: this.itensPorPagina };
      this.receberFiliais(paginacao);
    },

    abrirModalAdicionarFilial(tag) {
      this.exibirDialogAdicionarFilial = true
      this.tagDialog = tag
      this.keyDialog++
    },

    atualizarStatusFilial(item, statusId) {
      item.atualiazandoStatus = true;

      institucionalService
        .atualizarStatusFilial(item.id, { status: statusId })
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Status atualizado.',
            title: 'SUCESSO!'
          });

          const paginacao = { page: this.pagination.page, size: this.itensPorPagina };
          this.filtroAplicado ? this.aplicarFiltroFilial() : this.receberFiliais(paginacao);
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => item.atualiazandoStatus = false);
    },

    aplicarFiltroFilial() {
      this.resetarPaginacao();
      this.filtroAplicado = true;
      this.carregandoAcaoGenerica = true;
      this.pagination.page = 1;
      const paginacao = { page: 0, size: 10 };
      this.filtroFilial(paginacao);
      this.carregandoAcaoGenerica = false
    },

    limparFiltro() {
      this.resetarPaginacao();
      this.carregandoAcaoGenerica = true;
      this.filtro = { nome: '', cnpj: '', status: '' }
      this.pagination.page = 1;
      const paginacao = { page: 0, size: this.itensPorPagina };
      this.receberFiliais(paginacao);
      this.carregandoAcaoGenerica = false;
    },

    filtroFilial(paginacao) {
      const filtro = { filial: 'true', ...paginacao, ...this.filtro };
      institucionalService.filtroFilial(filtro)
        .then(({ data }) => {
          this.filiais = data.content.map(filial => {
            return {
              ...filial,
              telefone: this.setTelefone(filial.ddd, filial.telefone),
              cidade: filial.nomeCidade,
              atualiazandoStatus: false,
            }
          });
          this.pagination.total = data.totalPages
        });
    },

    page(page) {
      this.carregandoAcaoGenerica = true
      this.pagination.page = page - 1;
      const paginacao = { page: this.pagination.page, size: this.itensPorPagina };
      this.filtroAplicado ? this.filtroFilial(paginacao) : this.receberFiliais(paginacao);
      this.carregandoAcaoGenerica = false;
    },

    desabilitarBotaoDesativarFilial(item) {
      return item.idStatus === 1
        || item.idStatus === 3
        || !item.idStatus
    }
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (cep.length >= 8) {
        this.verificarCep(cep)
      }
    },

    'fields.idUf.value': function (id) {
      this.receberCidades(id)
    },

    cepValido: function (valor) {
      if (valor) {
        this.fields.cep.rules = [(value) => !!value || 'Campo obrigatório']
      }
      if (!valor) {
        this.fields.cep.rules = [
          (value) => !!value || 'Campo obrigatório',
          (value) => (this.cepValido && !!value) || 'CEP invalido'
        ]
      }
    },

    enderecoCep: function (valor) {
      this.listaUf.filter((uf) => {
        if (uf.sigla == valor.uf) {
          this.fields.idUf.valueField = uf.id
          this.fields.idUf.value = uf.id
        }
      })
      if (this.cepValido) {
        this.fields.idCidadeEndereco.items.filter((cidade) => {
          if (
            cidade.nome ==
            valor.localidade
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
          ) {
            this.fields.idCidadeEndereco.valueField = cidade.id
            this.fields.idCidadeEndereco.value = cidade.id
          }
        })
      }
    }
  },
}
</script>

<style scoped>
  .scroll {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
</style>
