var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('DialogConfirmation',{key:_vm.keyDialog,attrs:{"scrollable":true,"width":950,"show":_vm.exibirDialogAdicionarFilial,"tag":_vm.tagDialog,"title":_vm.dialogTitle},on:{"close":function($event){return _vm.fecharModalAdicionarFilial()},"confirm":function($event){return _vm.cadastrarFilial()}},scopedSlots:_vm._u([(_vm.tagDialog === 'add')?{key:"body",fn:function(){return [_c('v-form',{staticClass:"form-fields fields"},[_c('TitleH2',{staticClass:"mt-3 mb-5 mx-3",attrs:{"title":"Dados da Filial"}}),_c('FormField',{ref:"formCadastrarParceiro",attrs:{"validate":_vm.keyFormValidacao,"fields":_vm.fields}})],1)]},proxy:true}:null],null,true)}),_c('CrudTable',{attrs:{"tabelaKey":"id","tabelaComDetalhes":true,"headers":_vm.headers,"items":_vm.filiais,"pagination":_vm.pagination,"loading":_vm.carregandoAcaoGenerica,"keyPaginacao":_vm.keyPaginacao},on:{"actualPage":_vm.page,"clickRow":function($event){return _vm.detalharFilial($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:_vm.$vuetify.breakpoint.xs ? 'scroll' : '',attrs:{"flat":""}},[_c('ButtonMain',{staticClass:"mr-2",attrs:{"primary":true,"text":"Adicionar Filial"},nativeOn:{"click":function($event){return _vm.abrirModalAdicionarFilial('add')}}}),_c('v-spacer'),_c('ButtonMain',{attrs:{"text":"filtrar","primary":true},nativeOn:{"click":function($event){return _vm.abrirDialogFiltroParceiro()}}}),(_vm.filtroAplicado)?_c('ButtonMain',{staticClass:"ml-2 warning",attrs:{"text":"Limpar filtros","primary":true},nativeOn:{"click":function($event){_vm.limparFiltro()
            _vm.filtroAplicado = false}}}):_vm._e(),_c('DialogConfirmation',{key:_vm.keyDialog,attrs:{"title":"Filtros","scrollable":true,"width":720,"show":_vm.dialogFiltroAberto,"tag":'add'},on:{"close":function($event){_vm.dialogFiltroAberto = false},"confirm":function($event){_vm.aplicarFiltroFilial(); _vm.dialogFiltroAberto = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"mt-3"},[_c('InputField',{attrs:{"label":"Razão Social","cols":6,"valueField":_vm.filtro.nome},on:{"update:value":function($event){_vm.filtro.nome = $event}}}),_c('InputMaskField',{attrs:{"label":"Filtrar por CNPJ","maskFormat":"##.###.###/####-##","valueField":_vm.filtro.cnpj,"cols":6},on:{"update:value":function($event){_vm.filtro.cnpj = $event}}}),_c('SelectField',{attrs:{"label":"Filtrar por Status","keyValue":"id","keyName":"descricao","items":_vm.listaStatusFiliais,"cols":6,"valueField":_vm.filtro.status},on:{"update:value":function($event){_vm.filtro.status = $event}}})],1)]},proxy:true}])})],1)]},proxy:true},{key:"item.cnpj",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.cnpj,'##.###.###/####-##')))])]}},{key:"item.idStatus",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"color":_vm.getColor(item.idStatus)}},[_vm._v(" "+_vm._s(item.descricaoStatus ? item.descricaoStatus : 'NÃO INFORMADO')+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green","loading":item.idStatus !== 2 && item.atualiazandoStatus,"disabled":item.idStatus === 2 || item.atualiazandoStatus}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Ativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação alterará o status da filial " + (item.nome) + " para ATIVO. Deseja continuar? "))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.atualizarStatusFilial(item, 2)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"small":"","color":"red","loading":item.idStatus === 2 && item.atualiazandoStatus,"disabled":item.idStatus !== 2 || item.atualiazandoStatus}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Inativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação alterará o status da filial " + (item.nome) + " para INATIVO. Deseja continuar?"))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.atualizarStatusFilial(item, 3)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }